<template>
    <div>
        <el-row>
            <el-col>
                <div class="main_title">
                    Transaction
                </div>
                <div class="sub_title">
                    Last 100 transactions
                </div>
            </el-col>
        </el-row>
        <el-row class="mt-2">
            <el-col>
                <el-table :data="transactions" height="500" style="width: 100%">
                    <el-table-column prop="type" label="Type" width="180">
                        <template #default="scope">
                            <span class="text_table_status" style="color: red;" v-if="scope.row.type === 'sell'">SELL</span>
                            <span class="text_table_status" style="color: green" v-if="scope.row.type === 'buy'">BUY</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="Amount">
                        <template #default="scope">
                           <span class="text_table_number">{{ numberWithCommas(parseFloat(scope.row.amount).toFixed(0)) + " GESC" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="Price">
                        <template #default="scope">
                            <span style="color: #909399;">
                                {{ parseFloat(scope.row.price).toFixed(4) }} TON
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="timestamp" label="Time" width="180">
                        <template #default="scope">
                            <span style="color: #909399;">
                                {{ convertTime(scope.row.timestamp) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import http from "@/http";
import moment from "moment";
export default {
    /* eslint-disable */
    name: "Transaction",
    data() {
        return {
            transactions: []
        }
    },
    methods: {
        async getTransactions() {
            return http.get("/trading/last-transaction").then(res => {
                if (res.data.status === 200) {
                    this.transactions = res.data.data;
                }
            });
        },
        convertTime(timestamp) {
            return moment(timestamp).fromNow();
        },
        tableRowClassName({ row,
            rowIndex }) {
            if (row.type === 'sell') {
                return 'warning-row'
            } else if (row.type === 'buy') {
                return 'success-row'
            }
            return ''
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    mounted() {
        this.getTransactions();
    }
}
</script>
<style>
.el-table .warning-row {
    --el-table-tr-bg-color: var(--el-color-warning-light-9);
}

.el-table .success-row {
    --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>