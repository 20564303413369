<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-page-header :title="'GESC DEX'" :icon="'Promotion'" @back="onGoToBot()">
          <template #breadcrumb />
          <template #icon />
          <template #title />
          <template #content />
          <template #extra>
            <button id="connect-wallet"></button>
          </template>
          <template #default />
        </el-page-header>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { TonConnectUI } from '@tonconnect/ui';
// import { Buffer } from 'buffer';
// import { Address } from '@ton/ton';
import { ElLoading } from 'element-plus'
import { toast } from "vue3-toastify";
import EventBus from './event';
import http from "@/http";
import Footer from './components/includes/Footer.vue';
export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      interval: 5,
      tonConnectUI: null
    }
  },
  methods: {
    onGoToBot() {
      window.location.href = "https://t.me/gamesether_news";
    },
    async connectWallet(address) {
      return http.post("/trading/create-trader", {
        accountId: address
      }).then(res => {
        if (res.data.status === 200) {
          localStorage.setItem("address", address);
        }
      });
    },
    handleEvent(payload) {
      if (this.tonConnectUI) {
        this.getToken(payload);
      }
    },
    async verifyTrasaction(type) {
      return http.post("/trading/perpare-verify", { address: localStorage.getItem("address"), type });
    },
    async getToken(payload) {
      const loadingfull = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      return http.get("/trading/prepare-transaction", {
        params: {
          address: localStorage.getItem('address'),
          type: payload.type,
          amount: payload.number
        }
      }).then(async (res) => {
        if (res.data.status === 200) {
          if (payload.type === 'TON') {
            const message = res.data.message;
            const amount = payload.number;
            const nanoAmount = amount * (10 ** 9);
            const transaction = {
              validUntil: Math.floor(Date.now() / 1000) + 60,
              messages: [
                {
                  address: "UQA9g5L7QlcJUh6Nt93btBAkgaDp3_RJtyj1kDPiT2nYyKcI",
                  amount: nanoAmount,
                  payload: message
                }
              ]
            }

            try {
              await this.tonConnectUI.sendTransaction(transaction);
              setTimeout(() => {
                this.verifyTrasaction("TON").then(res => {
                  loadingfull.close();
                  if (res.data.status === 200) {
                    toast(res.data.message, {
                      "theme": "colored",
                      "type": "success",
                      "dangerouslyHTMLString": true
                    });
                  } else {
                    toast(res.data.message, {
                      "theme": "colored",
                      "type": "error",
                      "dangerouslyHTMLString": true
                    });
                  }
                });
              }, 20000);
            } catch (err) {
              console.log(err);
            }
          } else {
            const message = res.data.message;
            const address = res.data.address;
            const transaction = {
              validUntil: Math.floor(Date.now() / 1000) + 360,
              messages: [
                {
                  address: address,
                  amount: 0.09 * 10 ** 9,
                  payload: message
                }
              ]
            }

            try {
              await this.tonConnectUI.sendTransaction(transaction);
              setTimeout(() => {
                this.verifyTrasaction("GESC").then(res => {
                  loadingfull.close();
                  if (res.data.status === 200) {
                    toast(res.data.message, {
                      "theme": "colored",
                      "type": "success",
                      "dangerouslyHTMLString": true
                    });
                  } else {
                    toast(res.data.message, {
                      "theme": "colored",
                      "type": "error",
                      "dangerouslyHTMLString": true
                    });
                  }
                });
              }, 20000);
            } catch (err) {
              console.log(err);
            }
          }
        }
      });
    }
  },
  mounted() {
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: 'https://gamesether.com/tonconnect-manifest.json',
      buttonRootId: 'connect-wallet'
    });

    this.tonConnectUI.onStatusChange(walletAndwalletInfo => {
      if (walletAndwalletInfo && walletAndwalletInfo.account) {
        let address = walletAndwalletInfo.account.address;
        this.connectWallet(address);
      } else {
        localStorage.removeItem("address");
      }
    });

    EventBus.on("onTranfer", this.handleEvent);
  },
  created() {
    // @ts-ignore
    // window.Buffer = Buffer;
  },
  unmounted() {
    EventBus.off("onTranfer");
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
