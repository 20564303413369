<template>
    <el-dialog v-model="centerDialogVisible" title="Warning" align-center>
        <span>{{ content }}</span>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="centerDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="confirm">
                    Confirm
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
export default {
    name: "ConfirmDialog",
    data() {
        return {
            centerDialogVisible: false,
            content: "",
            type: ""
        }
    },
    methods: {
        open(type, number) {
            this.type = type;
            const amount = parseFloat(number).toFixed(4);
            this.content = `Are you sure to withdrawal ${amount} ${type} to wallet!`;
            this.centerDialogVisible = true;
        },
        confirm() {
            this.$emit("onConfirm", this.type);
            this.centerDialogVisible = false;
        }
    }
}
</script>