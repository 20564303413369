<template>
    <div>
        <el-row>
            <el-col>
                <div class="main_title">
                    App
                </div>
                <div class="sub_title">
                    App & Website using GESC Tokens
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
                <a href="https://t.me/gamesether_bot">
                    <div class="app_items">
                        <div class="app_items_left">
                            <img src="@/assets/img/gamesether.png" alt="">
                        </div>
                        <div class="app_items_right">
                            <div class="app_title">
                                Games Ether
                            </div>
                            <div class="app_sub_title">
                                The world games on TON chain
                            </div>
                        </div>
                    </div>
                </a>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
                <a href="https://t.me/cathunterx_bot">
                    <div class="app_items">
                        <div class="app_items_left">
                            <img src="@/assets/img/cathunter_x.png" alt="">
                        </div>
                        <div class="app_items_right">
                            <div class="app_title">
                                Cat Hunter X
                            </div>
                            <div class="app_sub_title">
                                Tap to earn Token
                            </div>
                        </div>
                    </div>
                </a>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
                <a href="https://likelik.com/">
                    <div class="app_items">
                        <div class="app_items_left">
                            <img src="@/assets/img/like_icon.png" alt="">
                        </div>
                        <div class="app_items_right">
                            <div class="app_title">
                                Liklik Social
                            </div>
                            <div class="app_sub_title">
                                Social & Digital Market
                            </div>
                        </div>
                    </div>
                </a>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
                <a href="https://token.gamesether.com/">
                    <div class="app_items">
                        <div class="app_items_left">
                            <img src="@/assets/img/pre_sale.png" alt="">
                        </div>
                        <div class="app_items_right">
                            <div class="app_title">
                                GESC Token
                            </div>
                            <div class="app_sub_title">
                                Roadmap & Pre-sale Token
                            </div>
                        </div>
                    </div>
                </a>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    /* eslint-disable */
    name: "Application"
}
</script>