import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/styles/main.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueApexCharts from "vue3-apexcharts";
import "vue3-toastify/dist/index.css";

const app = createApp(App).use(router).use(ElementPlus).use(VueApexCharts);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app');