<template>
    <el-dialog v-model="centerDialogVisible" :title="title" center>
        <p class="balance_text">Your Wallet</p>
        <el-row>
            <el-col>
                <el-input v-model="walletBalance" disabled placeholder="Please input">
                    <template #append>{{ label }}</template>
                </el-input>
            </el-col>
        </el-row>
        <el-row>
            <el-col class="center_row">
                <div id="mouse-scroll">
                    <div>
                        <span class="down-arrow-1"></span>
                        <span class="down-arrow-2"></span>
                        <span class="down-arrow-3"></span>
                    </div>
                </div>
            </el-col>
        </el-row>
        <p class="balance_text" style="margin-top: 30px;">GESC DEX</p>
        <el-row>
            <el-col>
                <el-input v-model="balanceTranfer" placeholder="Please input">
                    <template #append>{{ label }}</template>
                </el-input>
            </el-col>
        </el-row>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="close()">Cancel</el-button>
                <el-button type="primary" @click="onTranfer()" :disabled="isDisabledTranfer">
                    Tranfer
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import EventBus from '@/event';
export default {
    name: "CenterDialog",
    data() {
        return {
            centerDialogVisible: false,
            title: "",
            label: "",
            walletBalance: 0,
            balanceTranfer: 0,
            isDisabledTranfer: false,
        }
    },
    methods: {
        open(title, label, wallet) {
            this.title = title;
            this.label = label;
            this.centerDialogVisible = true;
            if (label === "GESC") {
                this.walletBalance = wallet.gesc;
                this.balanceTranfer = wallet.gesc;
            } else {
                this.walletBalance = wallet.ton;
                this.balanceTranfer = wallet.ton;
            }

            if (!this.walletBalance) {
                this.isDisabledTranfer = true;
            } else {
                this.isDisabledTranfer = false;
            }
        },
        onTranfer() {
            EventBus.emit("onTranfer", {
                number: this.balanceTranfer,
                type: this.label
            });
        },
        close() {
            this.centerDialogVisible = false;
        }
    }

}
</script>