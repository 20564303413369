<template>
    <div>
        <el-row>
            <el-col>
                <div class="main_title">
                    Trade
                </div>
                <div class="sub_title">
                    Buy & Sell GESC tokens
                </div>
            </el-col>
        </el-row>
        <el-row class="trading_block">
            <el-col :xs="24" :sm="12" :md="14" :lg="14" :xl="14">
                <div class="text-right">
                    <el-button @click="changeRange(5)" :type="interval === 5 ? 'primary' : 'default'">5m</el-button>
                    <el-button @click="changeRange(30)" :type="interval === 30 ? 'primary' : 'default'">30m</el-button>
                    <el-button @click="changeRange(60)" :type="interval === 60 ? 'primary' : 'default'">1H</el-button>
                </div>
                <apexchart width="100%" type="candlestick" :options="options" :series="series"></apexchart>
            </el-col>
            <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
                <div class="tab_block">
                    <el-tabs type="border-card" v-model="activeName" class="demo-tabs" @tab-click="onClickTab">
                        <el-tab-pane label="BUY" name="first">
                            <p class="balance_text" v-if="lastPrice">Current Price: {{ lastPrice }} TON</p>
                            <div class="form_item">
                                <el-input type="number" ref="gesc_input_buy" v-model="form.gesc" @input="onChangeNumber"
                                    style="max-width: 600px" placeholder="Enter number...">
                                    <template #append>GESC</template>
                                </el-input>
                            </div>
                            <div class="form_item">
                                <div class="percentage_number">
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddTon(25)">25%</el-button>
                                    </div>
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddTon(50)">50%</el-button>
                                    </div>
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddTon(75)">75%</el-button>
                                    </div>
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddTon(100)">100%</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="form_item">
                                <el-input disabled v-model="form.ton" style="max-width: 600px"
                                    placeholder="Enter number...">
                                    <template #append>TON</template>
                                </el-input>
                            </div>
                            <p v-if="tradingInfor" class="balance_text">You Have {{ tradingInfor.ton }} TON <span
                                    class="add_more_btn" @click="openDialogAddMore('TON')">Add
                                    More</span></p>
                            <hr>
                            <div class="inline_form">
                                <el-input disabled v-model="form.address" style="max-width: 600px"
                                    placeholder="Enter number...">
                                </el-input>
                                <div>
                                    <el-button :disabled="isEnableWidthdrawlTon()" type="primary"
                                        @click="onWithdrawal('TON')">Withdrawal</el-button>
                                </div>
                            </div>
                            <div class="inline_text" @click="openHistories">
                                Withdrawal Histories
                            </div>
                            <div class="form_submit">
                                <el-button type="success" :disabled="isDisabledSubmit" @click="buyToken">BUY
                                    GESC</el-button>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="SELL" name="second">
                            <p class="balance_text" v-if="lastPrice">Current Price: {{ lastPrice }} TON</p>
                            <div class="form_item">
                                <el-input ref="gesc_input_sell" type="number" v-model="form.gesc"
                                    @input="onChangeNumber" style="max-width: 600px" placeholder="Enter number...">
                                    <template #append>GESC</template>
                                </el-input>
                            </div>
                            <div class="form_item">
                                <div class="percentage_number">
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddGesc(25)">25%</el-button>
                                    </div>
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddGesc(50)">50%</el-button>
                                    </div>
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddGesc(75)">75%</el-button>
                                    </div>
                                    <div class="percentage_item">
                                        <el-button type="primary" plain @click="onAddGesc(100)">100%</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="form_item">
                                <el-input disabled v-model="form.ton" style="max-width: 600px"
                                    placeholder="Enter number...">
                                    <template #append>TON</template>
                                </el-input>
                            </div>
                            <p v-if="tradingInfor" class="balance_text">You Have {{ tradingInfor.gesc }} GESC <span
                                    class="add_more_btn" @click="openDialogAddMore('GESC')">Add
                                    More</span></p>
                            <hr>
                            <div class="inline_form">
                                <el-input disabled v-model="form.address" style="max-width: 600px"
                                    placeholder="Enter number...">
                                </el-input>
                                <div>
                                    <el-button :disabled="isEnableWidthdrawlGesc()" type="primary"
                                        @click="onWithdrawal('GESC')">Withdrawal</el-button>
                                </div>
                            </div>
                            <div class="form_submit">
                                <el-button type="danger" :disabled="isDisabledSubmit" @click="sellToken">SELL
                                    GESC</el-button>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="tab_block" style="margin-top: 20px;">
                    <claim ref="claim-dialog" :tableData="history" />
                </div>
            </el-col>
        </el-row>
        <center-dialog ref="center-dialog" />
        <confirm-dialog ref="confirm-dialog" @onConfirm="confirmClaim" />
    </div>
</template>
<script>
import http from "@/http";
import CenterDialog from "../dialog/CenterDialog.vue";
import ConfirmDialog from "../dialog/ConfirmDialog.vue";
import Claim from "./Claim.vue";
import { toast } from "vue3-toastify";
export default {
    /* eslint-disable */
    name: "Trading",
    components: {
        CenterDialog,
        ConfirmDialog,
        Claim
    },
    data() {
        return {
            form: {
                gesc: '',
                ton: 0,
                address: ""
            },
            activeName: "first",
            options: {
                chart: {
                    id: 'price-chart',
                    // background: 'black'
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                xaxis: {
                    labels: {
                        show: false
                    }
                }
            },
            series: [
                {
                    data: []
                }
            ],
            interval: 5,
            tradingInfor: null,
            lastPrice: null,
            isDisabledSubmit: true,
            wallet: {
                ton: 0,
                gesc: 0
            },
            gescPer: 0,
            tonPer: 0,
            history: []
        }
    },
    methods: {
        onAddTon(per) {
            if (!this.tradingInfor) {
                toast("Please connect wallet!", {
                    "theme": "colored",
                    "type": "error",
                    "dangerouslyHTMLString": true
                });
                return;
            }
            if (this.tonPer != per) {
                this.tonPer = per;
                const ton = (parseFloat(this.tradingInfor.ton) * (this.tonPer / 100)) - (parseFloat(this.tradingInfor.ton) * (0.1 / 100));
                this.form.ton = ton.toFixed(4);
                this.form.gesc = (this.form.ton / this.lastPrice).toFixed(4);
                this.onChangeNumber(this.form.gesc);
            }
        },
        changeRange(interval) {
            this.interval = interval;
            this.getCandles();
        },
        confirmClaim(type) {
            this.claimToken(type);
        },
        onAddGesc(per) {
            if (!this.tradingInfor) {
                toast("Please connect wallet!", {
                    "theme": "colored",
                    "type": "error",
                    "dangerouslyHTMLString": true
                });
                return;
            }
            if (this.gescPer != per) {
                this.gescPer = per;
                const gesc = (parseFloat(this.tradingInfor.gesc) * (this.gescPer / 100)) - (parseFloat(this.tradingInfor.gesc) * (0.1 / 100));
                this.form.gesc = gesc.toFixed(4);
                this.onChangeNumber(this.form.gesc);
            }
        },
        onWithdrawal(type) {
            if (type === "GESC") {
                this.$refs['confirm-dialog'].open(type, this.tradingInfor.gesc);
            } else {
                this.$refs['confirm-dialog'].open(type, this.tradingInfor.ton);
            }
        },
        openDialogAddMore(type) {
            this.getBalance();
            this.getBalceGesc();

            let title = "";
            let label = "";

            if (type === 'GESC') {
                label = "GESC";
                title = "Add More GESC From Wallet";
            } else {
                label = "TON";
                title = "Add More TON From Wallet";
            }
            this.$refs['center-dialog'].open(title, label, this.wallet);
        },
        onClickTab() {
            this.form.ton = 0;
            this.form.gesc = '';
            this.isDisabledSubmit = true;
            this.tonPer = 0;
            this.gescPer = 0;

            setTimeout(() => {
                this.$refs['gesc_input_buy'].focus();
                this.$refs['gesc_input_sell'].focus();
            }, 100);
        },
        isEnableWidthdrawlTon() {
            if (this.tradingInfor && this.tradingInfor.ton) {
                return false;
            } else {
                return true;
            }
        },
        isEnableWidthdrawlGesc() {
            if (this.tradingInfor && this.tradingInfor.gesc) {
                return false;
            } else {
                return true;
            }
        },
        onChangeNumber(value) {
            if (!this.form.gesc) {
                this.isDisabledSubmit = true;
                this.form.ton = 0;
                return;
            }

            const regex = /^[0-9]*\.?[0-9]*$/;
            if (regex.test(value)) {
                this.isDisabledSubmit = false;
            } else {
                this.isDisabledSubmit = true;
                return;
            }

            if (this.form.gesc < 1) {
                this.isDisabledSubmit = true;
                return;
            } else {
                this.isDisabledSubmit = false;
            }

            if (this.lastPrice) {
                let ton = parseFloat(this.form.gesc) * parseFloat(this.lastPrice);
                this.form.ton = parseFloat(ton.toFixed(4));
            }
        },
        openHistories() {
            this.$refs['claim-dialog'].open();
        },
        async getCandles() {
            return http.get("/trading/candles", { params: { interval: this.interval } }).then(res => {
                if (res.data.status === 200) {
                    let series = [];
                    for (let item of res.data.data) {
                        series.push({
                            x: item.timestamp,
                            y: [
                                parseFloat(item.open.toFixed(8)),
                                parseFloat(item.high.toFixed(8)),
                                parseFloat(item.low.toFixed(8)),
                                parseFloat(item.close.toFixed(8))
                            ]
                        });
                    }

                    this.series[0].data = series;
                }
            });
        },
        async getTradingInfor() {
            return http.get("/trading/trading-infor", {
                params: { accountId: localStorage.getItem("address") }
            }).then(res => {
                if (res.data.status === 200) {
                    this.tradingInfor = res.data.data;
                }
            });
        },
        async getLastPrice() {
            return http.get("/trading/last-price").then(res => {
                if (res.data.status === 200) {
                    this.lastPrice = res.data.price;
                }
            });
        },
        async getBalance() {
            const address = localStorage.getItem("address");
            http.get('/tradding/wallet-infor', { params: { address } }).then(res => {
                if (res.data.status === 200) {
                    this.wallet.ton = res.data.ton;
                }
            });
        },
        async getBalceGesc() {
            const address = localStorage.getItem("address");
            http.get('/trading/wallet-gesc', { params: { address } }).then(res => {
                if (res.data.status === 200) {
                    this.wallet.gesc = res.data.gesc;
                }
            });
        },
        async buyToken() {
            if (!localStorage.getItem("address")) {
                toast("Please connect wallet!", {
                    "theme": "colored",
                    "type": "error",
                    "dangerouslyHTMLString": true
                });

                return;
            }

            const address = localStorage.getItem("address");
            http.post('/trading/buy', { address, amount: this.form.gesc }).then(res => {
                if (res.data.status === 200) {
                    toast(res.data.message, {
                        "theme": "colored",
                        "type": "success",
                        "dangerouslyHTMLString": true
                    });
                    this.getTradingInfor();
                } else {
                    toast(res.data.message, {
                        "theme": "colored",
                        "type": "error",
                        "dangerouslyHTMLString": true
                    });
                }
            });
        },
        async sellToken() {
            if (!localStorage.getItem("address")) {
                toast("Please connect wallet!", {
                    "theme": "colored",
                    "type": "error",
                    "dangerouslyHTMLString": true
                });

                return;
            }

            const address = localStorage.getItem("address");
            http.post('/trading/sell', { address, amount: this.form.gesc }).then(res => {
                if (res.data.status === 200) {
                    toast(res.data.message, {
                        "theme": "colored",
                        "type": "success",
                        "dangerouslyHTMLString": true
                    });
                    this.getTradingInfor();
                } else {
                    toast(res.data.message, {
                        "theme": "colored",
                        "type": "error",
                        "dangerouslyHTMLString": true
                    });
                }
            });
        },
        async claimToken(type) {
            if (!localStorage.getItem("address")) {
                toast("Please connect wallet!", {
                    "theme": "colored",
                    "type": "error",
                    "dangerouslyHTMLString": true
                });

                return;
            }

            return http.post("/trading/claim", { address: localStorage.getItem("address"), type }).then(res => {
                if (res.data.status === 200) {
                    this.getClaimHistories();
                    toast(res.data.message, {
                        "theme": "colored",
                        "type": "success",
                        "dangerouslyHTMLString": true
                    });
                } else {
                    toast(res.data.message, {
                        "theme": "colored",
                        "type": "error",
                        "dangerouslyHTMLString": true
                    });
                }
            });
        },
        async getClaimHistories() {
            http.get("/trading/claim-histories", { params: { address: localStorage.getItem("address") } }).then(res => {
                if (res.data.status === 200) {
                    this.history = res.data.data;
                }
            });
        }
    },
    mounted() {
        this.getCandles();
        this.getLastPrice();
        if (localStorage.getItem("address")) {
            this.getTradingInfor();
            this.form.address = localStorage.getItem("address");
            this.getBalance();
            this.getBalceGesc();
            this.getClaimHistories();
            setTimeout(() => {
                this.$refs['gesc_input_buy'].focus();
                this.$refs['gesc_input_sell'].focus();
            }, 100);
        }
    },
}
</script>