<template>
    <div class="footer">
        <el-row>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <div class="footer_main_title">GESC TOKEN</div>
                <div class="footer_sub_title">Game & Social On TON chain</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <div class="footer_main_label">Apps & Websites</div>
                <div class="footer_sub_label"><a href="https://t.me/gamesether_bot">Games Ether Bot</a></div>
                <div class="footer_sub_label"><a href="https://t.me/cathunterx_bot">Cat Hunter X Bot</a></div>
                <div class="footer_sub_label"><a href="https://likelik.com/">Likelik Social & Market</a></div>
                <div class="footer_sub_label"><a href="https://token.gamesether.com/">Roadmap & Token Pre-sale</a></div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <div class="footer_main_label">Cooperation & Development</div>
                <div class="footer_sub_label"><a href="https://docs.google.com/forms/d/e/1FAIpQLSeQn6Dmyx67fdBgV_esuQwC4YGJsIeJVHPIz1sBld2DeBh2QQ/viewform">Advertisement</a></div>
                <div class="footer_sub_label"><a href="https://docs.google.com/forms/d/e/1FAIpQLSeQn6Dmyx67fdBgV_esuQwC4YGJsIeJVHPIz1sBld2DeBh2QQ/viewform">GESC Token</a></div>
                <div class="footer_sub_label"><a href="https://docs.google.com/forms/d/e/1FAIpQLSeQn6Dmyx67fdBgV_esuQwC4YGJsIeJVHPIz1sBld2DeBh2QQ/viewform">Applications Development</a></div>
                <div class="footer_sub_label"><a href="https://docs.google.com/forms/d/e/1FAIpQLSeQn6Dmyx67fdBgV_esuQwC4YGJsIeJVHPIz1sBld2DeBh2QQ/viewform">Websites Development</a></div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                <div class="footer_main_label">Contact</div>
                <div class="footer_sub_label"><a href="https://t.me/gamesether">Telegram</a></div>
                <div class="footer_sub_label"><a href="https://t.me/gamesether_news">Telegram Channel</a></div>
                <div class="footer_sub_label">Admin: admin@gamesether.com</div>
                <div class="footer_sub_label">Support: support@likelik.com</div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    /* eslint-disable */
    name: "Footer"
}
</script>