<template>
    <div>
        <div class="padding_block">
            <el-card>
                <template #header>News</template>
                <div class="default_list">
                    <div class="default_items">
                        <div class="default_items_left">
                            <img src="@/assets/img/new.png" alt="">
                        </div>
                        <div class="default_items_right">
                            The GESC DEX exchange is now live! You can trade GESC tokens directly from your wallet. Join
                            the AIR DROP or
                            pre-sale to collect as many tokens as possible.
                        </div>
                    </div>
                    <div class="default_items">
                        <div class="default_items_left">
                            <img src="@/assets/img/new.png" alt="">
                        </div>
                        <div class="default_items_right">
                            The first sale of GESC Tokens on the TON network is now open at the lowest price, only
                            0.000125 TON.
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
export default {
    /* eslint-disable */
    name: "News"
}
</script>