<template>
    <div>
        <el-carousel indicator-position="outside">
            <el-carousel-item>
                <img class="banner_logo" src="@/assets/img/banner_1.png" alt="">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    /* eslint-disable */
    name: "Banner"
}
</script>
<style scoped>
.el-carousel__item h3 {
    display: flex;
    color: #475669;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
:deep(.el-card__body) {
    max-height: 204px;
    overflow: auto;
}
</style>