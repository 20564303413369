<template>
    <el-dialog v-model="centerDialogVisible" title="Claim Histories" align-center>
        <el-table :data="tableData" style="width: 100%" height="300">
            <el-table-column prop="cur" label="Type" width="80" />
            <el-table-column prop="before_bal" label="Amount" width="100">
                <template #default="scope">
                    <span class="line_numbers">{{ scope.row.before_bal }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="frendyAddress" label="Address" />
            <el-table-column prop="status" label="Status">
                <template #default="scope">
                    <div class="status_line" v-if="scope.row.status === 1"><el-icon>
                            <CircleCheck />
                        </el-icon> Approved</div>
                    <div class="status_line" v-if="scope.row.status === 0" style="color:#eebe77;"><el-icon>
                            <Clock />
                        </el-icon> Pending</div>
                    <div class="status_line" v-if="scope.row.status === 2" style="color: #ff2d0a;"><el-icon>
                            <CircleClose />
                        </el-icon> Rejected</div>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="centerDialogVisible = false">Cancel</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
export default {
    /* eslint-disable */
    name: "Claim",
    props: {
        tableData: {
            type: Object,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            centerDialogVisible: false
        }
    },
    methods: {
        open() {
            this.centerDialogVisible = true;
        }
    }
}
</script>