<template>
    <div>
        <el-row>
            <el-col>
                <div class="main_title addition_title"><span>GESC</span> DEX</div>
                <h1 class="main_title_site">Exchange GESC and TON Coin with your TON wallet</h1>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="12" :md="16" :lg="16" :xl="16">
                <banner/>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                <news/>
            </el-col>
        </el-row>
        
        <application />
        <trading />
        <transaction/>
    </div>
</template>
<script>
import Application from './includes/Application.vue';
import Trading from './includes/Trading.vue';
import Banner from './includes/Banner.vue';
import News from './includes/News.vue';
import Transaction from './includes/Transaction.vue';
export default {
    /* eslint-disable */
    name: "Home",
    components: {
        Application,
        Trading,
        Banner,
        News,
        Transaction
    },
    data() {
        return {
            ws: null,
            trades: []
        }
    },
    methods: {
        closeWs() {
            this.ws.close();
        },
        connectWs() {
            this.ws = new WebSocket('ws://localhost:8080');
            this.ws.onopen = function () {
                console.log('WebSocket kết nối thành công');
            };

            this.ws.onmessage = function (event) {
                console.log(event);
            };

            // Xử lý khi có lỗi xảy ra
            this.ws.onerror = function (error) {
                console.error('Lỗi WebSocket:', error);
            };

            // Xử lý khi kết nối bị đóng
            this.ws.onclose = function () {
                console.log('WebSocket kết nối bị đóng');
            };
        }
    },
    mounted() {
        // if (this.ws) {
        //     this.closeWs();
        // }
        // this.connectWs();
    },
}
</script>
